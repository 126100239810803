//Entry point for the da auth client lib, with the Login UI as a stand-alone Vue-wrapped Web Component, for use in non-vue projects.
import Vue from 'vue';
import wrap from "@vue/web-component-wrapper";
import AuthClient, { LoginForm, UserProfile } from './vue';

//Install the AuthClient and router as plugin
Vue.use(AuthClient);
// The login form is already created by the vue build, but this also creates the user profile.
const userProfileComponent = wrap(Vue, UserProfile);
window.customElements.define("da-auth-user-profile", userProfileComponent);
window.daAuthClient = AuthClient;
export default LoginForm;
