// Package exports for the Auth Client for client import for Vue-client
// This is what is imported in client build environments such as webpack
// Exported functionality includes everything from the AuthClient module, as well as
// submodules that can be .used by the AuthClient, and helper Factory methods for
// creating an AuthClient module
import VueRouter from 'vue-router';
import AuthClient from './client/auth-client';

//All submodule exports available as well, which are injected into AuthClient.use.
import { AuthRouterVue } from './client/auth-router';
import LoginForm from './client/ui/LoginForm.vue';

import UserProfile from './client/ui/UserProfile.vue';
//Auth Client itself is available as the default
export default AuthClient;
export * from './client/auth-client';
export { AuthRouterVue };
export { LoginForm };
export { UserProfile };

//Vue Plugin
AuthClient.install = function (Vue, { router, loginComponent = LoginForm } = {}) {
    if (!router) {
        Vue.use(VueRouter);
        router = new VueRouter({ mode: 'history' });
        Vue.mixin({ router });
    }
    Vue.mixin({
      beforeCreate: async function () {
        // console.log('AuthClient.install.beforeCreate', Vue, this, router);
        //If this is the instance with the router (root instance), add login route and use the router
        if (this.$router && this.$router !== router) {
            console.error(`AuthClient Vue Plugin Must use same Vue Router as the Vue instance`);
        }
      }
    });
    const loginRoute = {
        path: '/login',
        name: 'Login',
        component: loginComponent
    }
    // Adds itself as the loginHandler/route
    if (router) {
        new AuthRouterVue({AuthClient, router, loginRoute});
    }
    Vue.component(LoginForm.name, LoginForm);
    Vue.component(UserProfile.name, UserProfile);
    const $daAuth = Vue.observable(AuthClient);
    Object.defineProperty(Vue.prototype, '$daAuth', {
      get: function get () { return $daAuth }
    });
    Object.defineProperty(Vue.prototype, '$daUser', {
      get: function get () { return $daAuth.currentUser}
    });
}
