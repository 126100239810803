import AsyncEventEmitter from 'async-eventemitter';

/*
 This auth client is a wrapper for the cognito API.
 Promisifies the Cognito API for use wiuth async/await.
 */
import { getUser as getCognitoUser,
         getCurrentUser as getCurrentCognitoUser,
         getSession as getCognitoSession,
         authenticateUser as authenticateCognitoUser,
         completeNewPasswordChallenge,
         getUserAttributes as getCognitoUserAttributes,
         forgotPassword as cognitoForgotPassword,
         confirmPassword as cognitoConfirmPassword } from '../cognito';

//Change routing implementation set in .use on setup
let _loginHandler = null;
const evt = new AsyncEventEmitter();
const on = (...args) => {
    evt.on(...args);
}

class AuthChallenge extends Error {
    constructor({ message = 'Auth Challenge', code = -1, challenge, prompt, response, props }) {
        super(message);
        this.code = code;
        this.challenge = challenge;
        this.prompt = prompt;
        this.response = response;
        this.props = props;
    }
}

class AuthenticationError extends Error {
    constructor(e) {
        super(e);
        this.status = 401;
    }
}


class User {
    constructor() {
        // Only inititalize reactive props
        // getters don't need initialization
        this.userAttributes = null;
    }

    get isAuthenticated() {
        return !!this.auth;
    }

    async sendResetCode() {
        const { CodeDeliveryDetails: { Destination: sentCodeTo } } = await cognitoForgotPassword(this.cognitoUser);
        this.sentCodeTo = sentCodeTo;
        return this;
    }

    async confirmPassword({ resetCode, password }) {
        return cognitoConfirmPassword(this.cognitoUser, { resetCode, password });
    }

    async authenticate({ password, remember }) {
        try {
            const session = await authenticateCognitoUser(this.cognitoUser, { password, remember });
            await this.setUserSession(session);
            return this;
        } catch (err) {
            throwAuthError.call(this, err);
        }
        return this;
    }

    async setUserSession(cognitoSession) {
        this.cognitoSession = cognitoSession;
        await this.updateUserSession();
    }

    async refresh() {
        if (this.cognitoUser) {
            try {
                const session = await getCognitoSession(this.cognitoUser);
                await this.setUserSession(session);
                return this;
            } catch(e) {
                // This fails silently.  .freshToken will throw an error and redirect.
                console.info(`Failed to refresh user ${this.username}`, e.message);
            }
        }
        return this;
    }

    async updateUserSession() {
        this.userAttributes = await getCognitoUserAttributes(this.cognitoUser);
        return this;
    }

    get idToken() {
        return this.cognitoSession && this.cognitoSession.idToken;
    }

    get username() {
        return this.cognitoUser && this.cognitoUser.username;
    }

    get groups() {
        return this.idToken && this.idToken.payload["cognito:groups"];
    }

    get email() {
        return this.idToken && this.idToken.payload.email;
    }

    get name() {
        return this.idToken && this.idToken.payload.given_name || this.username;
    }

    get admin() {
        return this.groups && this.groups.indexOf('org:darkhorse') !== -1;
    }

    /**
     * Convenience to retrieve any auth tokens that may be needed from the user session
     * If isValid is false, then must await user.freshAuth instead to use tokens
     */
    get auth() {
        // Makes the auth reactive to idToken
        if (!this.idToken) {
            return null;
        }
        const cognitoToken = this.cognitoSession && this.cognitoSession.accessToken
        const accessToken =  cognitoToken && cognitoToken.jwtToken;
        const bearer = accessToken && `Bearer ${accessToken}`;
        const headers = accessToken && { Authorization: bearer };
        const expires = cognitoToken && new Date(cognitoToken.getExpiration() * 1000);
        return {
            isValid: () => accessToken && this.cognitoSession.isValid(),
            accessToken,
            headers,
            bearer,
            expires
        }
    }

    // Async, must await this property when using.
    // Returns auth with valid tokens, refreshed if necessary.
    get freshAuth() {
        return (async () => {
            if (!this.auth || !this.auth.isValid()) {
                await this.refresh();
            }
            if (!this.auth || !this.auth.isValid()) {
                return await showLogin();
            }
            return this.auth;
        })();
    }

    logout() {
        this.cognitoUser && this.cognitoUser.signOut();
        this.cognitoSession = null;
    }
}

function createUser(cognitoUser) {
    const user = new User(cognitoUser);
    user.cognitoUser = cognitoUser;
    return user;
}

function login({ username, password, remember }) {
    return getUser(username).authenticate({ password, remember });
}

/**
 * Invokes the loginHandler to get user credentials if one is specified
 */
async function showLogin() {
    if (_loginHandler) {
        return await _loginHandler();
    } else {
        throw new AuthenticationError('User Authentication Required');
    }
}

// Used by login, and any other functions that don't first need a session,
// just a username (password reset, MFA, confirmation codes, etc)
// DOES NOT set the current user, that is set in the functions that use this one.
function getUser(username) {
    let cognitoUser = getCognitoUser(username.toLowerCase());
    return createUser(cognitoUser);
}

function createRememberedUser() {
    let cognitoUser = getCurrentCognitoUser();
    if(cognitoUser) {
        const user = createUser(cognitoUser);
        return user;
    }
    return new User();
}

// Returns a change password challenge for user's to change password.
async function passwordChallenge({ username }, sendCode) {
    try {
        let user = getUser(username);
        const challenge = { passwordReset: user };
        let prompt = `Please enter a Reset Code for ${user.username} and change your password.`;
        if (sendCode) {
            user = await user.sendResetCode();
            prompt = `${prompt} A Reset Code has been sent to: ${user.sentCodeTo}`;
        } else {
            try {
                // Fail attempted authenticate to get a user that can confirmPassword with a code.
                user = await user.authenticate({password:'none'});
            } catch (e) {
                if (e.code !== 'NotAuthorizedException' && e.code !== 'PasswordResetRequiredException') {
                    throw e;
                }
            }
        }
        return new AuthChallenge({
            props: { resetCode: true, password: true, verify: true },
            prompt,
            challenge,
            response: async function(props) {
                verifyMatch(props);
                return user.confirmPassword(props);
            }
        });
    } catch (e) {
        if (e.code === 'InvalidParameterException') {
            throw new Error('Please enter your Username');
        }
        console.warn('passwordChallenge Error', e);
        throw e;
    }
}

class AuthClient {
    constructor() {
        this.currentUser = createRememberedUser();
        // Server side loginRedirects have originalUrlParam
        const originalUrlParam = window.location.search.match(/\?originalUrl=(.*)/);
        const originalUrl = originalUrlParam && originalUrlParam[1];
        if (originalUrl) {
            const url = new URL(originalUrl, window.location.origin);
            if (url.searchParams.get('logout') !== null) {
                url.searchParams.delete('logout');
                this.currentUser.logout();
            }
            this.redirect = async () => {
                const resp = await fetch(url, {
                    method: 'GET',
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'Authorization': this.currentUser.auth.bearer,
                    }
                });
                if (!resp.ok) {
                    const message = `Login Successful, but error redirecting to url ${originalUrl}: ${resp.status}`;
                    throw new Error(message);
                }
                window.location.replace(resp.url);
            };
        }
    }
    async login({ username, password, remember }) {
        this.currentUser = await login({ username, password, remember });
        if (this.redirect) {
            return await this.redirect();
        }
        evt.emit('login');
        return this.currentUser;
    }
    async logout() {
        evt.emit('logout');
        return new Promise((resolve) => {
            this.currentUser.logout();
            this.currentUser = new User();
            evt.emit('loggedout');
            resolve(this.currentUser);
        });
    }
}

AuthClient.prototype.on = on;
AuthClient.prototype.use = use;
AuthClient.prototype.passwordChallenge = passwordChallenge;
AuthClient.prototype.showLogin = showLogin;

const authClientSingleton =  new AuthClient();

function use({ loginHandler }) {
    _loginHandler = loginHandler;
    AuthClient.prototype.loginHandler = loginHandler;
    return AuthClient;
}

function verifyMatch({password, verify }) {
    const match = (password === verify);
    if (!match) {
        throw new Error('Passwords do not match, please try again.');
    }
}
/**
 * Checks for challenges in the error,
 * if none, just throw the error.
 * If there are challenges, then create a challenge and throw
 */
function throwAuthError(err) {
    const { challenge } = err;
    if (challenge) {
        if (challenge.newPasswordRequired) {
            throw new AuthChallenge({
                ...err,
                props: { resetCode: false, password: true, verify: true },
                prompt: `Please enter a new password for ${this.username}`,
                response: async ({ password, verify }) => {
                    verifyMatch({ password, verify });
                    await completeNewPasswordChallenge(this.cognitoUser,
                              { password, challengeAttributes: challenge.newPasswordRequired });
                    return authClientSingleton.login({ username: this.username, password });
                }
            });
        }
    } else if (err.code === 'PasswordResetRequiredException') {
        err.challenge = {
            passwordResetRequired: { username: this.username }
        };
        throw new AuthChallenge({
            ...err,
            props: { resetCode: true, password: true, verify: true },
            prompt: `Password Reset Required for ${this.username}`,
            response: async ({ password, verify, resetCode }) => {
                verifyMatch({ password, verify });
                await this.confirmPassword({ password, verify, resetCode });
                return authClientSingleton.login({ username: this.username, password });
            }
        });
    }
    throw err;
}


export { showLogin, use, on, getUser, passwordChallenge, AuthChallenge };
export default authClientSingleton;
