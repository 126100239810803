// import {isAuthenticated, refreshUser} from './auth-client';
// import LoginForm from './ui/LoginForm.vue';
// Override default in plugin install,


/*
Adds a Navigation Guard to the Vue Router, routes to login if not authrorized for the route.

Contains a showLogin method and a onLogin method that use the router to
navigate to the correct routes.
 */
import Vue from 'vue';


export class AuthRouterVue {
    constructor({ AuthClient, loginRoute, router} = {}) {
        this.loginRoute = loginRoute;
        router.loginRoute = loginRoute;
        this.router = router;
        //Inject the Vue Router into the Auth client
        AuthClient.use({
            loginHandler: this.doLogin.bind(this)
        });
        AuthClient.on('login', this.onLogin.bind(this));
        AuthClient.on('logout', this.onLogout.bind(this));
        //Add the login route if it doesn't exist
        if (!router.options.routes || !router.options.routes.includes(loginRoute)) {
            router.addRoutes([loginRoute]);
        }

        router.beforeEach(async (to, from, next) => {
            const { currentUser } = AuthClient;
            if (this.noAuth(to) || currentUser.isAuthenticated) {
                return next();
            } else {
                try {
                    this.routeAfterLogin = to;
                    // In case session is old when they refresh a guarded link,
                    // gets a fresh session.
                    const auth = await currentUser.freshAuth;
                    if (auth) {
                        return next();
                    }
                } catch(e) {
                    console.info(`Authorization failed for route: ${to.path}:${e.message}`);
                }
            }
        });
    }

    // Checks if a given route has the noAuth meta tag
    noAuth(route) {
        return !route.matched.some(record => record.meta.noAuth !== true && record.path !== this.loginRoute.path);
    }

    doLogin() {
        if (this.router.currentRoute.path !== this.loginRoute.path) {
            this.router.push(this.loginRoute);
        }
    }

    onLogin() {
        // If login was invoked because of a freshAuth exception, or vav to a guarded route,
        // this will be set.
        if (this.routeAfterLogin){
            return this.router.replace({...this.routeAfterLogin});
        } 
        this.router.go(-1);
    }

    onLogout() {
        this.doLogin();
    }
}
