<template>
    <div class="da-user-profile da-auth">
        <div
            class="button link label"
            :title="$daUser.email || 'Not Logged In'"
            @click="isDropdownActive=!isDropdownActive"
        >
            <slot id="dropLabelSlot" name="label">
                <span>{{ dropdownLabel }}<span v-show="showDropdown" style="font-size:xx-small;"> &#x25BC;</span></span>
            </slot>
        </div>
        <div class="dropdown-panel" v-show="showDropdown && isDropdownActive">
                <div v-if="$daUser.isAuthenticated">
                    <div class="profile">
                        Logged in as <b>{{ $daUser.name }}</b>
                        <div class="email">{{ $daUser.email }}</div>
                    </div>
                    <span
                        class="button"
                        type="submit"
                        @click="logout()"
                    >Logout</span>
                </div>
                <router-link
                    v-else
                    :to="{name:'Login'}"
                >
                 <span class="button link" @click="isDropdownActive=false;">Login</span>
                </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserProfile',
    options: {},
    props: {
        label: {
            type: String,
            default: null
        }
    },
    data: () => ({
        isDropdownActive: false,
        showProfile: false
    }),
    computed: {
        dropdownLabel() {
            return this.label || window.location.hostname || 'Darkhorse';
        },
        showDropdown() {
            // Don't show the dropdown when on login page and user has not yet loggedin
            return this.$daUser.isAuthenticated || this.$route && (this.$route.name !== this.$router.loginRoute.name);
        }
    },
    methods: {
        async logout() {
            this.isDropdownActive=false;
            await this.$nextTick();
            this.$daAuth.logout();
        }
    }
};
</script>
<style lang="scss">
@import "./Auth.scss";
.da-user-profile {
    // font: 400 13.3333px Arial;
    z-index: 101;
    position: absolute;
    right:0;
    top: 0;
    padding: 0.3em;
    text-align:right;
    .button.label {
         color: currentColor;
    }
    // .logout-button {
    //     width: 100%;
    //     display: block;
    //     cursor: pointer;
    //     margin: 0.6em 0;
    //     border: solid 1px $md-light-colour;
    //     border-radius: $rad-sm;
    //     text-align:center;
    //     // width: 9.5em;
    //     padding-top:0.3em;
    //     padding-bottom:0.3em;
    //     &:hover {
    //         font-weight: bold;
    //         background-color: $md-light-colour
    //     }
    // }
    .dropdown-panel {
        padding: 0.3em;
        border: solid 1px $md-light-colour;
        background-color: $light-colour;
        border-radius: $rad-md;
        text-align:center;
        // padding: 10px;
        .email {
            font-size:small;
        }
    }
}
</style>
