/*
Wrapper for all usage of amazon-cognito-identity-js
for use with Cognito User Pool

 Code is from examples here
 https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js

 Found the SDK docs:
 https://gerardnico.com/aws/cognito/js_identity

 There is now also an npm page:
 https://www.npmjs.com/package/amazon-cognito-identity-js
*/

/* expired access token for testing
eyJraWQiOiJKelJqOGlNcEFnTFg2S0ExNkw1T25TdXdib0NKS0wzZTNTajZFQm9Vb0hVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmZjM0ZmI0Yy1iNTIzLTRhMDUtYWY3Yy1hNGNiOTViNGZlMjEiLCJjb2duaXRvOmdyb3VwcyI6WyJvcmc6Y3QiLCJvcmc6YWZycyIsIm9yZzpwZyIsIm9yZzplcHMiLCJvcmc6ZGFya2hvcnNlIiwib3JnOmVvcCIsIm9yZzp2ZnJzIiwib3JnOmhwcyIsIm9yZzpnb3RoYW0iLCJvcmc6c2NlcyIsIm9yZzplbXMiLCJvcmc6aG9uZXN0ZG9vciIsIm9yZzpyb2Jib3giLCJvcmc6a2ZkX2Rpc3BhdGNoOmFsbCIsImVtcyIsIm9yZzpsZWR1YyIsIm9yZzpkZW1vIiwib3JnOnNhbmRib3giLCJvcmc6ZGF0YW1hcnNoIiwib3JnOmtmZCIsIm9yZzp1ZnNhIiwib3JnOmJmZCIsIm9yZzp0ZnMiLCJvcmc6a2ZkX2Rpc3BhdGNoIiwib3JnOnRlc3Rmb2xkZXIiLCJvcmc6aGZkIl0sImV2ZW50X2lkIjoiZTMzOWQ0NDktNWM5MS00NTYyLWJiZGUtZjRmMzdmYjE0NWZmIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTYwMDI4NTUwMSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfbEVmVEdKMzNKIiwiZXhwIjoxNjAwNDUxMDc4LCJpYXQiOjE2MDA0NDc0NzksImp0aSI6IjNlMDNhNDZiLWMwMTUtNDEyYy1hYjEzLWY5OWJjMDMwMjc2ZSIsImNsaWVudF9pZCI6IjI2cmZjcmFwYTM4MmUxMThmcTNrbGllYWQ2IiwidXNlcm5hbWUiOiJhbGFuIn0.eyvSbcrJAJ9rJMZnboQfc46YJeSRCDCgeg1pDLwwP25g8J8kuzGMJta7E1NeGeRp2-BriDJX75zTR2wi-nMWZIymRWby9LoYbxfpEDhHhrZQiJrnYiiE0YsPS-xX7FxE4R7b3h9B8SVMdRaPVm_DgHrtq2_otcagfeCftbsbthpDwV4qWe-xtEsAomb0kc08V8UXQC4OpeSjfAJpek2UpZqeIu7w_Mk6ccvoVkCvzLxsO5sNtVMmJo63sOwE9LEZeaq_fAWD3-siM0bMt_Rr1EQedInUELPHKt9hFoPA0Z_10Z67yVPYBUX3B7yDJ54nAGwoasKQkyuh-2rLVS-EEg
*/

import 'cross-fetch/polyfill';
import { config } from 'dotenv';
import { AuthenticationDetails,
    CognitoUserPool,
    CognitoUser } from 'amazon-cognito-identity-js';

/*
NOTE:
This file is used by both server and client for AWS Env.
Server environment can be set with regular environment vars, or dotenv.

Vue apps can set with VUE_APP_ environment variables, which are set at BUILD time
if using the Vue CLI to build your app.

Library / Client runtime environment variables:
Need to set a DA_AUTH environment on the window object.

 */
config();
const {
    AWSREGION,
    COGNITOUSERPOOLID,
    COGNITOCLIENTID,
    VUE_APP_COGNITOUSERPOOLID,
    VUE_APP_COGNITOCLIENTID,
    VUE_APP_AWSREGION } = process && process.env;
const awsEnv = {
    AWSREGION: AWSREGION || VUE_APP_AWSREGION || 'us-west-2',
    COGNITOUSERPOOLID: COGNITOUSERPOOLID || VUE_APP_COGNITOUSERPOOLID,
    COGNITOCLIENTID: COGNITOCLIENTID || VUE_APP_COGNITOCLIENTID
};

// DA_AUTH can be attached to window in browser with no process.env
if (typeof window !== "undefined" && window.DA_AUTH) {
    const daAuthEnv = window.DA_AUTH;
    awsEnv.COGNITOUSERPOOLID = daAuthEnv.COGNITOUSERPOOLID;
    awsEnv.COGNITOCLIENTID = daAuthEnv.COGNITOCLIENTID;
    awsEnv.AWSREGION = daAuthEnv.AWSREGION || awsEnv.AWSREGION;
}

if (!awsEnv.COGNITOCLIENTID || !awsEnv.COGNITOUSERPOOLID) {
    const desAppPool = {
        COGNITOUSERPOOLID: 'us-west-2_upKtXYdPf',
        COGNITOCLIENTID: '30aqfc0601e2r25c9e0nr2ogh7'
    }
    console.warn('COGNITOUSERPOOLID/COGNITOCLIENTID not set.  Using DHA Test User Pool.', desAppPool);
    awsEnv.COGNITOUSERPOOLID = desAppPool.COGNITOUSERPOOLID;
    awsEnv.COGNITOCLIENTID = desAppPool.COGNITOCLIENTID;
} else if (VUE_APP_COGNITOUSERPOOLID && VUE_APP_COGNITOUSERPOOLID === awsEnv.COGNITOUSERPOOLID) {
    console.warn('Using COGNITOUSERPOOLID bundled with VUE_APP_COGNITOUSERPOOLID set:', awsEnv);
}

const userPool = new CognitoUserPool({
    UserPoolId : awsEnv.COGNITOUSERPOOLID,
    ClientId : awsEnv.COGNITOCLIENTID
});


function getUser(username) {
    var userData = {
        Username : username,
        Pool : userPool
    };
    return new CognitoUser(userData);
}

function getSession(cognitoUser) {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        });
    });
}

function forgotPassword(cognitoUser) {
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function getUserAttributes(cognitoUser) {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((err, attributeList) => {
            if (err) {
                reject(err);
            } else {
                resolve(attributeList);
            }
        });
    });
}

function completeNewPasswordChallenge(cognitoUser, { password, challengeAttributes }) {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(password, challengeAttributes, {
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function confirmPassword(cognitoUser, { resetCode, password }) {
    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(resetCode, password, {
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function authenticateUser(cognitoUser, { password }) {
    var authenticationData = {
        Password : password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: resolve,
            // username/Password invalid, any other errors
            onFailure: reject,
            // New Password required
            newPasswordRequired: (userAttributes, e) => {
                delete userAttributes.email_verified;
                reject({
                    message: `Temporary Password Change Required`,
                    challenge: { newPasswordRequired: userAttributes }
                });
            }
        });
    });
}

function getCurrentUser() {
    return userPool.getCurrentUser();
}

export { forgotPassword, confirmPassword, getSession, getUser, authenticateUser, getCurrentUser, getUserAttributes, completeNewPasswordChallenge, awsEnv}
